<template>
  <sentiment-details></sentiment-details>
</template>

<script>
import sentimentDetails from '@/components/staff/sentimentDetails'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    sentimentDetails
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_GET_SENTIMENT_DETAILS)
  }
}
</script>
