<template>
  <v-card>
    <v-card-title>コメント</v-card-title>
    <v-card-subtitle>調子を変えられますよ。</v-card-subtitle>
    <sentiment-picker :index="index_" @save="onSentimentPickerSave"></sentiment-picker>
    <v-card-subtitle>コメントもいただけますか？</v-card-subtitle>
    <v-card-text>
      <v-textarea
        dense
        rows="2"
        prepend-icon="mdi-comment-outline"
        v-for="f in fields"
        :key="f.label"
        v-model="f.comment"
        :label="f.label"
        clearable
      ></v-textarea>
      <v-row justify="center">
        <v-btn color="primary" text @click="save">保存</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import sentimentPicker from './sentimentPicker'

export default {
  name: 'sentiment-details',
  components: {
    sentimentPicker
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    fieldNames: {
      type: Array,
      required: true
    }
  },
  computed: {
    index_: {
      get () {
        return this.index
      },
      set (value) {
        this.$emit('indexChanged', value)
      }
    }
  },
  data: () => ({
    fields: null
  }),
  methods: {
    onSentimentPickerSave (index) {
      this.index_ = index
    },
    async save () {
      const data = {
        index: this.index,
        fields: this.fields
      }
      this.$emit('save', data)
    }
  },
  created () {
    this.fields = this.fieldNames.map(n => {
      return {
        label: n,
        comment: ''
      }
    })
  }
}
</script>
